/* login.css */
.login-section-pss {
    margin-top: 6%;
    /* height: 100vh; */
    display: flex;
    align-items: center;
}

.login-container {
    max-width: 900px;
    margin: auto;
}

.link-button-pss{
    background-color: green;
    color: white;
}

.card {
    border: 1px solid #dee2e6;
    border-radius: 10px;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    display: flex;
    flex-direction: row;
}

.image-container {
    flex: 1;
    padding: 0;
}

.image {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.form-container {
    flex: 1;
    padding: 2rem;
}

.form-header {
    margin-bottom: 2rem;
}

.form-header h2 {
    font-size: 2rem;
    color: #343a40;
}

.form-header h3 {
    font-size: 1rem;
    color: #6c757d;
    margin: 0;
}

.form-row-pss {
    gap: 1rem;
    display: flex;
    flex-wrap: wrap;
}

.form-group {
    flex: 1 1 45%;
}

.form-group label {
    font-weight: 600;
}

.form-group input {
    padding: 10px;
    font-size: 0.9rem;
    border-radius: 5px;
    border: 1px solid #ced4da;
}

.form-group input[type="text"],
.form-group input[type="password"] {
    width: 100%;
}

.form-actions {
    flex: 1 1 100%;
}

.d-grid {
    display: grid;
}

.btn {
    background-color: #007bff;
    border-color: #007bff;
    padding: 10px 15px;
    font-size: 1rem;
    border-radius: 5px;
    transition: background-color 0.3s, border-color 0.3s;
}

.hr {
    margin-top: 2rem;
    margin-bottom: 1.5rem;
    border-color: #dee2e6;
}

.text-center {
    margin: 0;
    color: #6c757d;
    text-align: center;
    display: flex;
    justify-content: space-evenly;
}

.link {
    color: #007bff;
    text-decoration: none;
    transition: color 0.3s;
}
.profile{
    width: 100%;
    display: flex;
    justify-content:right;
}
.profile-img{
    width: 3%;
    margin-top: 2%;
    margin-right: 10%;
}
.person-name{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: serif;
    height: 20vh;
    font-size: 20px;
}


@media (max-width:500px) {
    .image-container{
        display: none;
    }
    .form-row {
        display: grid;
        flex-wrap: wrap;
    }
    .section {
        padding: rem 0;
    }
    .form-container {
        padding:0 2rem;
    }
    .profile{
        width: 100%;
        display: flex;
        justify-content:center;
    }
    .profile-img{
        width: 15%;
        margin-right: 0%;
        margin-top: 2%;
    }
}