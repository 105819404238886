/* navbar */
.navbar
{
  margin:3% 0%;
  font-size: 20px;
  background-color: white;
}
.srkrlogo
{
  width: 100px;
}
.clgdept
{
  margin-top: 1%;
  text-align: center;
  font-size: 3.5rem;
  background: linear-gradient(180deg, #2900de, #b10f0f);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.ast{
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10.5rem;
  background: linear-gradient(270deg,rgb(12, 214, 46),rgb(137, 119, 244),rgb(218, 10, 218), #4f3aea,rgb(70, 1, 70), rgb(227, 22, 22),#34036c,rgb(31, 185, 219));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-family:Georgia, 'Times New Roman', Times, serif;
  opacity: 30%;
}

.deptname
{
  width: 100%;
  margin-top: 5%;
}
@media screen and (max-width: 767px)
{
  .srkrlogo
  {
    width: 50px;
  }
  .clgdept
  {
    margin-top: 4.5%;
    font-size: 1rem;
  }
  .ast{
    font-size: 2.5rem;
  }
}