/* UpdateForm Component CSS */

.update-form-section {
    padding: 3rem 0;
  }
  
  .update-form-container {
    max-width: 900px;
    margin: auto;
    border: 1px solid #dee2e6;
    border-radius: 10px;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    display: flex;
    flex-direction: row;
  }
  
  .update-form-image {
    flex: 1;
    padding: 0;
  }
  
  .update-form-image img {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .update-form-content {
    flex: 1;
    padding: 2rem;
  }
  
  .update-form-heading h2 {
    font-size: 2rem;
    color: #343a40;
  }
  
  .update-form-heading h3 {
    font-size: 1rem;
    color: #6c757d;
    margin: 0;
  }
  
  .update-form-input {
    flex: 1 1 45%;
  }
  
  .update-form-input label {
    font-weight: 600;
  }
  
  .update-form-input input {
    padding: 10px;
    font-size: 0.9rem;
    border-radius: 5px;
    border: 1px solid #ced4da;
  }
  
  .update-form-button {
    flex: 1 1 100%;
    margin-top: 2%;
  }
  
  .update-form-button .btn {
    background-color: #007bff;
    border-color: #007bff;
    padding: 10px 15px;
    font-size: 1rem;
    border-radius: 5px;
    transition: background-color 0.3s, border-color 0.3s;
  }
  
  .update-form-button .btn:hover {
    background-color: #0056b3;
    border-color: #0056b3;
  }
  
  .update-form-footer {
    margin-top: 2rem;
    margin-bottom: 1.5rem;
    text-align: center;
  }
  
  .update-form-footer p {
    margin: 0;
    color: #6c757d;
  }
  
  .update-form-footer a {
    color: #007bff;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .update-form-footer a:hover {
    color: #0056b3;
  }

  .img-fluid{
    width: 80%;
  }
  .form-row{
    display: grid;
  }

  @media (max-width:500px) {
    .update-form-image{
        display: none;
    }
    .form-row {
        display: grid;
        flex-wrap: wrap;
    }
    .section {
        padding: rem 0;
    }
    .form-container {
        padding:0 2rem;
    }
}
  