#app {
  border: solid 2px #2c313c;
  justify-content: center;
  padding: 10px 20px;
  align-items: center;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: max-content;
  z-index: 999999;
  background-color: white;
}
.base-timer {
  position: relative;
  width: 300px;
  height: 300px;
}
.timer-title {
  align-items: center;
  justify-content: center;
  text-align: center;
}

/* scaleX(1) = CLOCKWISE, scaleX(-1) = ANTI-CLOCKWISE */
.base-timer__svg {
  transform: scaleX(1);
}

.base-timer__circle {
  fill: none;
  stroke: none;
}

.base-timer__path-elapsed {
  stroke-width: 7px;
  stroke: grey;
}

.base-timer__path-remaining {
  stroke-width: 7px;
  stroke-linecap: round;
  transform: rotate(90deg);
  transform-origin: center;
  transition: 1s linear all;
  fill-rule: nonzero;
  stroke: currentColor;
}

.base-timer__path-remaining.arc {
  color: rgba(179, 48, 102, 0.973);
}

.base-timer__label {
  position: absolute;
  width: 300px;
  height: 300px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 48px;
}

.buttons {
  box-sizing: border-box;
  display: flex;

  justify-content: center;
  align-items: center;
}

button.exit-fullscreen {
  border: none !important;
  width: max-content !important;
  color: black;
  background-color: transparent !important;
  position: absolute;
  top: -5px;
  right: -10px;
}

button.exit-fullscreen:hover {
  border: darkblue;
}

.exit-fullscreen {
  position: absolute;
}

.timer-list-sidebar {
  position: fixed;
  top: 0;
  right: 0;
  width: 400px;



  height: 100%;
  background-color: #ffffff;
  border-left: 1px solid #080808;
  transition: transform 0.3s ease; 
  transform: translateX(
    100%
  ); 
  border-radius: 20px;
  z-index: 1000; 
}
@media  (max-width: 768px) {
 .timer-list-sidebar {

  
  border-radius:0;
  width: 100%;
  }
  
 }
.timer-list-sidebar:not(.open) button{
  position: relative;
  right: 50px;

}

.timer-list-sidebar button{
  border-radius: 10px;

  img{
width: 40px;
height: auto;

  }

}

.timer-list-sidebar.open {
  transform: translateX(
    0
  ); 
}

.sidebar-content {
 
  padding: 10px;
}

.timer-list {
  height:500px;
  overflow-y: scroll;
  margin-top: 20px;
  flex-grow: 1;
}

/* Custom scrollbar styles */
.timer-list::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

.timer-list::-webkit-scrollbar-track {
  background: #f0f0f0; /* Background of the scrollbar track */
}

.timer-list::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar thumb */
  border-radius: 5px; /* Rounded corners */
}

.timer-list::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color when hovered */
}
.timer-list h2 {
  margin-bottom: 10px;
}

.close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: transparent;
  border: none;
  color: #007bff;
  width: max-content !important;
  cursor: pointer;
}
.close-btn:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  border-radius: 50%;
  background-color: transparent;
}

.timer-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
  background-color: #f9f9f9;
  transition: all 0.3s ease;
}

.timer-content {
  flex-grow: 1;
}

.timer-countdown {
  font-size: 24px;
}

.maximize-btn {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  width: max-content;
  transition: background-color 0.3s ease;
}

.maximize-btn:hover {
  background-color: #0056b3;
}

/* Apply styles for maximized state */
.timer-item.maximized {
  width: 100%;
  border-radius: 0;
  border: none;
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 0;
}

.timer-item.maximized .timer-content {
  padding: 20px;
}

.timer-item.maximized .timer-countdown {
  font-size: 36px;
}

.timer-item.maximized .maximize-btn {
  display: none;
}
