.h1-tasks {
    color: #333;
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 2rem;
    font-weight: bold;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.problemstatements {
    width: 100%;
    justify-content: center;
    display: flex;
}

.task-form {
    width: 80%;
    height: 100%;
}

.tasks-align {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
}

.task-item {
    background: white;
    padding: 2rem;
    border: 1px solid #e0b7ef;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(211, 25, 25, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    margin: 2%;
}

.task-list {
    display: flexbox;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1.5rem;
    width: 100%;
    max-width: 1200px;
}

.task-title {
    font-size: 1.4rem;
    margin-bottom: 0.5rem;
    color: #322142 !important;

}

.task-description {
    font-size: 1.1rem;
    color: #555;


}
.list-ps thead tr {
    background-color: lightgreen !important;
    color: black !important;
}

.task-select {
    text-align: end;
    display: flex;
    justify-content: right;
}

.task-select2 {
    width: 30%;
    text-align: end;
    display: flex;
    justify-content: space-evenly;
}

@media (max-width:770px) {
    .task-select {
        display: flex;
        justify-content: center;
    }

    .task-select2 {
        width: 100%;
        justify-content: space-evenly;
    }
}

@media (max-width:500px) {

    /* .task-item {
        display: grid;
        justify-content:center;
    } */
    .task-select,
    .task-select button {
        margin-top: 5%;
        font-size: 10px;
        display: flex;
        justify-content: center;
    }

    .task-box {
        width: 100%;
    }

    .task-title,
    .task-description {
        text-align: center;
    }

    .task-select {
        display: flex;
        justify-content: center;
    }

    .task-select2 {
        width: 100%;
        justify-content: space-evenly;
    }

    .task-manager {
        width: 100%;
    }
}

.limitText1{
    width: 200px;
    text-wrap: wrap;
}
.limitText2{
    width: 600px;
    justify-content:initial;
    text-wrap: wrap;
}