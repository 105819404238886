
.tasks-align {
    display: flex;
    flex-direction: column;
}

.task-item {
    background:white;
    border: 1px solid #e0b7ef;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(211, 25, 25, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    display: flex;
    justify-content: space-between;
}

.task-list {
    display:flexbox;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1.5rem;
    width: 100%;
}

.task-title {
    font-size: 1.4rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: #102C57; 

 
    
}
.task-description {
    font-size: 1.1rem;
    color: #1679AB;
}

@media (max-width:600px) {
    .task-item {
        display: grid;
        justify-content:start;
    }
    .task-select,.task-select button{
        margin-top: 5%;
        font-size: 10px;
        display: flex;
        justify-content: center;
    }
   
    .task-title{
        font-size: 20px;
    }
}