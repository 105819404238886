.game {
  text-align: center;
  padding: 10px;
}

.game *{

  @media (max-width:420px) {
    transform: scale(0.9);
  }


}
.score,
.high-score {
  font-size: 24px;
}

.game-nav-bar {
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding: 4px;
  height: 50px;

  h3{
    color: #9f6f51;
    a{
        color: black;
    }
    a:hover{
      color: #9f6f51;

    }
  }
}
.game-score {
  display: flex;
  gap: 20px;
}

.game-controls {
  height: 90px;

  button {
    margin: 20px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }
}

.info-button {
  position: fixed !important;
  bottom: 30px;
  right: 20px;
  z-index: 1000;
}
.info-div{
  position: relative;
}

.game-area{
    margin: 30px 0;
    padding: 20px 0;
    background-color: #9f6f51;
    cursor: url('../../../../public/hammer.png'), auto;

}
.holes {
  display: grid;
  width: 100%;
  justify-content: center;
  grid-template-columns: repeat(4, 250px);
  grid-gap: calc(100% - 5 * 300px);

  @media (max-width: 720px) {
    grid-template-columns: repeat(3, 200px);
    grid-gap: calc(100% - 4 * 400px);


    
  }

}

.hole {
  width: 240px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  transition: background-color 0.3s;
  background-image: url("../../../../public/hole.png");
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  background-position: bottom;
  @media (max-width: 720px) {
    grid-template-columns: repeat(3, 200px);
    transform: scale(0.8);
    width: 220px;
  }
  @media (max-width: 420px) {
    grid-template-columns: repeat(3, 100px);
    transform: scale(0.8);
    width: 220px;
    
  }
}

.hole.pig::before {
  content: "";
  background-image: url("../../../../public/pig.png");
  background-size: contain;
  width: 130px;
  height: 130px;
  position: relative;
  top: 10px;
  left: 10px;
  z-index: 1;
  animation: popOut 0.5s forwards;
  background-repeat: no-repeat;
  animation: popIn 0.5s forwards;
}
.h1-animation{
    margin-top: 10px !important;
}

@keyframes popOut {
  0% {
    transform: translateY(-10%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes popIn {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10%);
  }
}
