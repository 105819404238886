.select-long .form-control {
  width: 100%;
}

.registration-section {
  padding: 1rem 0;
}

.registration-container {
  max-width: 1000px;
  margin: auto;
  border: 1px solid #dee2e6;
  border-radius: 10px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
}

.registration-image {
  flex: 1;
  padding: 0;
}

.registration-image img {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  width: 130%;
  height: 90%;
  object-fit: cover;
  object-position: center;
}

.registration-form-container {
  flex: 1;
  padding: 2rem;
}

.registration-header {
  margin-bottom: 2rem;
}

.registration-header h2 {
  font-size: 2rem;
  color: #403435;
}

.registration-header h3 {
  font-size: 1rem;
  color: #6c757d;
  margin: 0;
}

.registration-form-group {
  gap: 1rem;
  display: flex;
  flex-wrap: wrap;
}

.registration-form-group .col-12.col-md-6 {
  flex: 1 1 45%;
}

.registration-form-group label {
  font-weight: 600;
}

.registration-form-group .form-control {
  padding: 10px;
  font-size: 0.9rem;
  border-radius: 5px;
  border: 1px solid #ced4da;
}

.d-grid {
  display: grid;
}

.bsb-btn-xl {
  background-color: #007bff;
  border-color: #007bff;
  padding: 10px 15px;
  font-size: 1rem;
  border-radius: 5px;
  transition: background-color 0.3s, border-color 0.3s;
}

.bsb-btn-xl:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}

.registration-divider {
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  border-top: 1px solid #dee2e6;
}

.registration-footer-text {
  margin: 0;
  color: #7d6c6c;
  text-align: center;
}

.registration-footer-text a {
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s;
}

.registration-footer-text a:hover {
  color: #0056b3;
}



@media (max-width: 500px) {
  .registration-container {
    flex-direction: column;
    border: none;
    box-shadow: none;
  }

  .registration-image {
    display: none;
  }

  .registration-form-container {
    padding: 1rem;
  }

  .registration-form-group .col-12.col-md-6 {
    flex: 1 1 100%;
  }

  .bsb-btn-xl {
    padding: 8px 10px;
    font-size: 0.875rem;
  }

  .registration-header h2 {
    font-size: 1.5rem;
  }

  .registration-header h3 {
    font-size: 0.875rem;
  }

  .registration-divider {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
