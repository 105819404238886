.vedhicheading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh;
  background: white;
}

.more-info-button {
  animation: zigzag 1s infinite ease-in, zigzag 2s infinite ease-out;
}







@keyframes zigzag {

  0%,
  100% {}

  50% {
    transform: translateY(-20px);
  }


}

.background-static-container {
  position: fixed;
  top:80vh;
  left: 0;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  z-index: 2;
}

.overlay-main-container {
  position: relative;
  top:5vh;
  pointer-events: none;
  @media (max-width:500px) {
    top:10vh;
  }
}

.overlay-main-container.visible {
  pointer-events: auto;
}



.sticky-scroll-view * {
  pointer-events: auto;
}

.more-info-button {
  cursor: pointer;
}


@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 1s ease-out forwards;
}

.slide-up {
  animation: slideUp 1s ease-out forwards;
}


.main {
  width: 100%;
  display: grid;
  align-items: center;
}

#style-4 {

  user-select: none;
}

#style-4::-webkit-scrollbar {
  display: none;
}


.imgdiv {
  width: 100%;
  display: flex;
  justify-content: center;
}

.hacthonimg {
  width: 40%;
  margin-top: 3%;
  height: 40vh;
  border: 5px #3498db solid;
}

.hacthongrid {
  width: 100%;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-pic{
  margin-top: 30vh;
  @media (max-width:500px) {
    margin-top: 20vh;
  }
}

.hacthongrid-home {
  width: 100%;
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width:500px) {
    height: 50vh;
  }
}

.hacthonlist button {
  height: 6vh;
  border-radius: 6px;
  border: none;
}

.hacthonlist {
  width: 75%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: center;
}

.buttonsgrid {
  border-radius: 10px;
}

.hacthonlist button:nth-child(1) {
  background-color: #3498db;
  color: rgb(44, 44, 139);
  font-weight: bold;
  font-size: large;
}

.hacthonlist button:nth-child(2) {
  background-color: #3498db;
  color: rgb(44, 44, 139);
  font-weight: bold;
  font-size: large;
}

.hacthonlist button:nth-child(3) {
  background-color: #3498db;
  color: rgb(44, 44, 139);
  font-weight: bold;
  font-size: large;
}

.hacthonlist button:nth-child(4) {
  background-color: #3498db;
  color: rgb(44, 44, 139);
  font-weight: bold;
  font-size: large;
}

.hacthonlist button:hover {
  background-color: blue;
  color: white;
}

.aboutgrid {
  /* width: 10%; */
  display: flex;
  justify-content: center;
}

.CrackedText {
  font-family: Arial, sans-serif;
  font-size: 3em;
  font-weight: 900;
  /* Double bold */
  text-transform: uppercase;
  color: transparent;
  background-image: linear-gradient(to right,
      rgb(56, 54, 54),
      rgb(242, 185, 232),
      rgb(6, 48, 84),
      rgb(4, 150, 190),
      rgb(33, 44, 171),
      rgb(232, 137, 137),
      rgb(171, 71, 218),
      rgb(179, 236, 179),
      rgb(69, 219, 82));
  background-size: 200% 200%;
  -webkit-background-clip: text;
  background-clip: text;
  position: relative;
  animation: rainbow 5s ease-in-out infinite;
}

.CrackedText::before {
  content: "VEDIC VISION";
  position: absolute;
  left: 0;
  top: 0;
  color: transparent;
  background-image: linear-gradient(to right,
      black,
      rgb(109, 108, 105),
      rgb(236, 134, 134),
      rgb(4, 150, 190),
      rgb(33, 44, 171),
      rgb(184, 109, 219),
      rgb(179, 236, 179));
  background-size: 200% 200%;
  -webkit-background-clip: text;
  background-clip: text;
  animation: rainbow 3s ease-in-out infinite;
  clip-path: polygon(0% 0%,
      10% 10%,
      20% 0%,
      30% 10%,
      40% 0%,
      50% 10%,
      60% 0%,
      70% 10%,
      80% 0%,
      90% 10%,
      100% 0%,
      100% 100%,
      90% 90%,
      80% 100%,
      70% 90%,
      60% 100%,
      50% 90%,
      40% 100%,
      30% 90%,
      20% 100%,
      10% 90%,
      0% 100%);
  z-index: -1;
}

@keyframes rainbow {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@media (max-width: 500px) {
  .CrackedText {
    font-size: 1em;
  }
}
#main-content{
  position: relative;
  top: 0;
}