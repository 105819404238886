@import url("https://fonts.googleapis.com/css2?family=Dhurjati&display=swap");

/* main code for css */

.section {
  min-height: 100vh;
  background-color: black;
  width: 100%;
  overflow: hidden !important;
}

section#page-1 {

  color: white;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

#page-1 {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.background-video {
  transform: rotate(180deg) translateY(20%) scale(2);
  position: static;
  top: 100%;
  left: 100%;
  width: auto;
  height: auto;

  @media (max-width: 900px) {

    transform: rotate(180deg) scale(3);

  }

  @media (max-width: 600px) {

    transform: rotate(180deg) scale(4);

  }
}

section#page-1 h1 {
  position: absolute;
  top: 25vh;
  font-weight: 500;
  font-size: 10vw !important;
  font-family: "Dhurjati", "Times New Roman", Times, serif, Times, serif;
  font-style: italic;
  text-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  color: #fafb63;
  align-items: center;
  line-height: 100px;
  justify-content: space-between;

  @media (min-height: 720px) {
    line-height: 40px;
    font-size: 4rem !important;
  }

}

.com-head {
  position: absolute;
  width: 100vw !important;
  height: 100vh;
  border-radius: 8px;
}

.com-info {
  display: flex;
}

.com-info-block-1 {
  flex: 1 1 50%;
  width: 100% !important;
  padding: 150px 5%;

  p {
    font-size: 20px;
    color: white;
    margin: 10px 0 !important;

    @media (max-width: 480px) {
      font-size: 16px;
    }
  }

  @media (max-width: 480px) {
    margin: 0 0;
    padding: 40px 40px;
  }
}

.com-info-block-1 h1 {
  font-size: 90px !important;
  line-height: 50px;
  font-family: "Dhurjati", "Times New Roman", Times, serif, Times, serif;
  color: #6bfff7;
  font-style: italic;
  text-align: left;
}

.info-boxes {
  margin: 40px 0;

  span {
    width: 24px;
    height: 30px;
    background: rgb(107, 255, 247);
    background: linear-gradient(86deg,
        rgba(107, 255, 247, 1) 0%,
        rgba(250, 251, 99, 1) 100%);
    border-radius: 5px;
    padding: 4px;
    font-weight: 500;
    transform: skew(-25deg);
  }
}

.com-info-block-2 {
  flex: 1 1 50%;
  padding: 40px;
  background-image: url("../../public/floral.png");
  color: #6bfff7;
  background-repeat: no-repeat;
  background-size: 100%;
  background-clip: border-box;
  margin: 0 7%;
  background-position: center;

  display: flex;
  justify-content: center;
  align-items: center;
}

.com-info-block-2 video {
  width: 300px;
  display: block;
  border-radius: 50%;
  height: 300px;
  object-fit: cover;

  @media (max-width: 480px) {
    width: 200px;
    height: 200px;
  }
}

@media screen and (max-width: 920px) {
  .com-info {
    flex-direction: column;
  }

  .com-info-block-1 {
    flex: 1 1 auto;
    margin-bottom: 10px;
    /* large scale bottom margin */
  }

  .com-info-block-2 {
    flex: 1 1 auto;
    margin-top: 10px;
    /* large scale top margin */
  }
}

#page-3 {
  padding: 5% 5%;
}

.logo-image {
  position: absolute;
  top: 10%;
  left: 50%;
  display: flex;
  justify-content: space-between;
  width: 140px;
  height: 60px;
  transform: translate(-50%, -10%);
  transition: transform 0.5s, scale 1.5s, z-index 1s;
  span {
    text-align: center;
    font-display: inherit;
    padding: 0px;
    margin: 0px;
    line-height: 0mm;
  }

  

  img {
    width: 60px;
  }

  h4 {
    padding: 0px !important;
    margin: 0px !important;
    line-height: 10px;
    line-height: normal;
    letter-spacing: 5px;
    font-family: "Dhurjati", "Times New Roman", Times, serif, Times, serif;
    font-style: italic;

    color: #fafb63;
  }

  p {
    position: inherit;
    color: white;
    font-size: small;
    font-weight: 100 !important;
  }
}

.logo-image1 {
  position: absolute;
  top: 20%;
  left: 20%;
  display: flex;
  justify-content: space-between;
  width: 140px;
  height: 60px;
  transform: translate(-50%, -10%);
  transition: transform 0.5s, scale 1.5s, z-index 1s;
  span {
    text-align: center;
    font-display: inherit;
    padding: 0px;
    margin: 0px;
    line-height: 0mm;
  }

  

  img {
    width: 250px;
  }

  h4 {
    padding: 0px !important;
    margin: 0px !important;
    line-height: 10px;
    line-height: normal;
    letter-spacing: 5px;
    font-family: "Dhurjati", "Times New Roman", Times, serif, Times, serif;
    font-style: italic;

    color: #fafb63;
  }

  p {
    position: inherit;
    color: white;
    font-size: small;
    font-weight: 100 !important;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.logo-container {
  position: relative;
  display: inline-block;
}

.logo-container::before {
  content: 'AST';
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  /* Adjust as needed */
  height: 30px;
  /* Adjust as needed */
  background-color: black;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  font-weight: bold;
  font-size: 10px;
  color: #c0aa7b;
}
.logo-container img{
  height: 65px;
  width: 60px;
}

.logo-rotate {
  transform: scale(2) !important;
  animation: rotate 15s linear infinite;
}



.com-features {
  display: flex;
  gap: 60px;
  width: 100%;

  @media (max-width: 480px) {
    flex-direction: column;
  }

  h1 {
    color: #fafb63;
    font-style: italic;
    font-weight: 600;
    font-size: 40px;
    font-family: "Dhurjati", "Times New Roman", Times, serif, Times, serif;
    text-align: left !important;
  }

  p {
    color: rgb(227, 224, 224);
    font-size: 18px;
  }

  hr {
    width: 12%;
    border: 3px solid white;
    opacity: 1;
  }
}

.feature-card {
  width: calc(100% / 3);

  @media (max-width: 480px) {
    width: 100%;
  }
}

.feature-img {
  display: flex;
  justify-content: center;
}

.com-features img {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  object-fit: cover;
}

@media (max-width: 480px) {
  .why-choose-us img {
    width: 400px;
  }
}

/* landingpage.css */

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 2s ease-in;
}

.slide-in {
  animation: slideIn 1.5s ease-in-out;
}